.burger {
  height: 24px;
  width: 24px;

  line {
    stroke: white;
    stroke-width: 3px;
    transition: all 0.2s ease-out;
  }
}

.open {
  .burger {
    line:first-child {
      transform: rotate(45deg);
      transform-origin: 1px 6px;
    }

    line:nth-child(2) {
      stroke-opacity: 0;
    }

    line:last-child {
      transform: rotate(-45deg);
      transform-origin: 2px 18px;
    }
  }
}
