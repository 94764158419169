@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  min-height: 100vh;
  line-height: inherit;
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  font-family: var(--font-plex);
  --toastify-color-success: #00cc87;
  --toastify-color-info: #0049ff;
  --toastify-color-error: #ff006b;
}

main {
  /* 80px is the header height */
  min-height: calc(100vh - 80px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.Toastify__toast-container {
  z-index: 9999; /* or any value that is higher than other elements on the page */
}

.Toastify__close-button {
  align-self: center;
}

.Toastify__close-button > svg {
  width: 24px;
  height: 24px;
}

.scrollbar::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #151617;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #454545;
  border-radius: 20px;
  border: 3px solid #454545;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #454545;
}

.shiki {
  padding: 10px;
  border-radius: 16px;
  max-width: 800px;
  text-wrap: wrap;
}
